var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('app-table',{attrs:{"type":"creators","columns":[
          'provider',
          'creator',
          'er',
          'impressions',
          'reach',
          'engagement',
          'created_at',
          'actions' ]}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <div class="row">
      <div class="col-12">
        <app-table
          type="creators"
          :columns="[
            'provider',
            'creator',
            'er',
            'impressions',
            'reach',
            'engagement',
            'created_at',
            'actions',
          ]"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
    }
  },
}
</script>
